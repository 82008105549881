.btn-qty-custom-left{
    border: 1px solid grey;
    border-radius: 1px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-right: 10px;
}

.btn-qty-custom-left:hover{
    opacity: 0.5;
}


.btn-qty-custom-right{
    border: 1px solid grey;
    border-radius: 1px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-left: 10px;
}

.btn-qty-custom-right:hover{
    opacity: 0.5;
}

.input-qty-custom{
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;

}

.page-header {
	padding: 4.6rem 0 5rem;
	background-color: #ebebeb;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	h1 {
		font: normal 400 3.2rem/1.1;
		letter-spacing: -.025em;
		margin-bottom: 0;


		span {
			display: block;
			font-size: 1.6rem;
			margin-top: .8rem;
		}
	}

	&.page-header-big {
		display: flex;
		position: relative;
		min-height: 250px;
		margin-bottom: 5rem;

		h1 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			span {
				font-size: 1.6rem;
				margin-top: .4rem;
				letter-spacing: 0;
			}
		}
	}
}


.summary{
	margin-top: 1rem;
}
.summary-title{
	font-weight: bold;
	font-size: 16px;
}


.label_form{
	font-weight: bold;
	margin-top: 15px;
}

.show_hide{
    transition: 0.3;
	cursor: pointer;
}

.show_hide:hover{
    opacity: 0.6;
}

.checkout-title {
	font-weight: 700;
	font-size: 1.4rem;
	letter-spacing: 0;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}